import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import productCarouselStyles from './product-carousel.module.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { useDebouncedCallback } from 'use-debounce';

const ProductCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulProductCarousel {
        buttonLabel
        products {
          id
          name
          model
          productPath
          image {
            file {
              url
              fileName
            }
          }
        }
      }
    }
  `);

  const [slideIndex, setSlideIndex] = useState(0);
  const [dragging, setDragging] = useState(false);
  const slider: any = useRef();
  const settings = {
    className: 'center',
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
    touchThreshold: 1000,
    speed: 900,
    beforeChange: (current: any, next: any) => {
      setSlideIndex(next);
      setDragging(true);
    },
    afterChange: () => setDragging(false),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slideNext = () => {
    slider.current.slickNext();
    trackCustomEvent({
      category: 'Navigation',
      action: 'Click',
      label: 'Carousel Slide Next Product',
      value: 43,
    });
  };

  const slidePrev = () => {
    slider.current.slickPrev();
    trackCustomEvent({
      category: 'Navigation',
      action: 'Click',
      label: 'Carousel Slide Previous Product',
      value: 43,
    });
  };

  const dragAndDrop = () => {
    trackCustomEvent({
      category: 'Navigation',
      action: 'Click',
      label: 'Carousel Grab and Drag',
      value: 43,
    });
  };

  const productClick = (productName: string) => {
    trackCustomEvent({
      category: 'Navigation',
      action: 'Click',
      label: `Carousel ${productName} selected`,
      value: 43,
    });
  };

  const allWorkClick = () => {
    trackCustomEvent({
      category: 'Navigation',
      action: 'Click',
      label: `Carousel See All Work button`,
      value: 43,
    });
  };

  const moveSliderDebounced = useDebouncedCallback(slide => {
    slider.current.slickGoTo(slide);
  }, 300);

  return (
    <div className={productCarouselStyles.carouselContainer}>
      <div className={productCarouselStyles.wrap}>
        <div className={productCarouselStyles.productNav}>
          <button onClick={() => slidePrev()}>
            <span className="lcarot" />
          </button>
          <button onClick={() => slideNext()}>
            <span className="rcarot" />
          </button>
        </div>

        <input
          value={slideIndex}
          onChange={e => {
            const slide = parseInt(e.target.value, 10);
            setSlideIndex(slide);
            moveSliderDebounced.callback(slide);
          }}
          type="range"
          min={0}
          max={data.contentfulProductCarousel.products.length - 1}
        />
      </div>

      <Slider ref={slider} {...settings}>
        {data.contentfulProductCarousel.products.map((product: any) => (
          <div
            key={product.id}
            className={`${productCarouselStyles.containerItem} 'items'`}
          >
            <Link
              onClick={e => {
                if (!dragging) {
                  // let the link do it's thing and register the action
                  productClick(product.name);
                } else {
                  // prevent the link from work while dragging
                  e.preventDefault();
                }
              }}
              to={`/product/${product.productPath}`}
            >
              <img
                className={productCarouselStyles.containerItemImage}
                src={product.image.file.url}
                alt={product.image.file.fileName}
                width="450px"
              />
              <p>
                <strong>{product.name}</strong> {product.model}
              </p>
            </Link>
          </div>
        ))}
      </Slider>

      <div className={productCarouselStyles.seeMore}>
        <Link
          onClick={() => allWorkClick()}
          to={'/section/work'}
          className="btn arrow"
        >
          {data.contentfulProductCarousel.buttonLabel}
        </Link>
      </div>
    </div>
  );
};

export default ProductCarousel;
